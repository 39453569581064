import React, { setState, useState, useEffect, forceUpdate } from "react";

import { Header, Segment, Grid, Dropdown, Button } from "semantic-ui-react";
import _ from "lodash";

import TilesList from "./TilesList";
import TileNew from "./TileNew";
import MobileTiles from "../mobile/Tiles/MobileTiles";
import {
  fetchTiles,
  fetchPages,
  update_Tile,
  fetchJunctionPages,
  fetchPathways,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";

const Tiles = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tiles = useSelector((state) => state.tiles);
  const junctionPages = useSelector((state) => state.junctionPages);
  const pages = useSelector((state) => state.pages);
  const pathways = useSelector((state) => state.pathways);

  const [pathway_object_dropdown, update_pathway_object_dropdown] = useState(
    []
  );
  const [selected_tiles, update_selected_tiles] = useState([]);

  useEffect(() => {
    dispatch(fetchTiles(user["custom:trust"]));
    dispatch(fetchPages(user["custom:trust"]));
    dispatch(fetchJunctionPages(user["custom:trust"]));
    dispatch(fetchPathways(user["custom:trust"]));
  }, []);

  useEffect(() => {
    const new_od = [...tiles];

    const ordered_tiles = _.sortBy(new_od, ["index_number"]);
    update_selected_tiles(ordered_tiles);
  }, [tiles]);

  useEffect(() => {
    const result = createPathwayObject();
    update_pathway_object_dropdown(result);
  }, [pathways]);

  function updateIndexNumberManual(id, index_numnber) {
    dispatch(
      update_Tile(
        {
          Item: {
            id: id,
            index_number: index_numnber,
          },
        },
        user["custom:trust"]
      )
    );
  }

  async function moveSection(index_number, movement) {
    const number_of_tiles = tiles.length;
    if (movement === "up" && index_number !== 0) {
      const moving_tile = _.find(tiles, { index_number: index_number });
      const tile_above = _.find(tiles, { index_number: index_number - 1 });
      moving_tile.index_number = index_number - 1;
      tile_above.index_number = index_number;
      dispatch(
        update_Tile(
          {
            Item: {
              id: moving_tile.id,
              index_number: moving_tile.index_number,
            },
          },
          user["custom:trust"]
        )
      );
      dispatch(
        update_Tile(
          {
            Item: { id: tile_above.id, index_number: tile_above.index_number },
          },
          user["custom:trust"]
        )
      );
    }
    if (movement === "down" && index_number !== number_of_tiles - 1) {
      const moving_tile = _.find(tiles, { index_number: index_number });
      const tile_below = _.find(tiles, { index_number: index_number + 1 });
      moving_tile.index_number = index_number + 1;
      tile_below.index_number = index_number;
      dispatch(
        update_Tile(
          {
            Item: {
              id: moving_tile.id,
              index_number: moving_tile.index_number,
            },
          },
          user["custom:trust"]
        )
      );
      dispatch(
        update_Tile(
          {
            Item: { id: tile_below.id, index_number: tile_below.index_number },
          },
          user["custom:trust"]
        )
      );
    }
  }

  function createPathwayObject() {
    let pathway_object = [{ id: "None", text: "None", value: "None" }];
    pathways.map((pathway) => {
      pathway_object.push({
        id: pathway.id,
        text: pathway.title,
        value: pathway.id,
      });
    });
    return pathway_object;
  }

  function updateSelection(value) {
    if (value === "None") {
      update_selected_tiles([...tiles]);
      return;
    }
    console.log(value);
    let new_tiles_object = [];
    tiles.map((tile) => {
      if (tile.junction_page_selected === false) {
        new_tiles_object.push(tile);
      }
      if (tile.junction_page_selected === true) {
        const selected_junction_page = _.find(junctionPages, {
          id: tile.JunctionPage,
        });
        if (selected_junction_page === undefined) {
          return;
        }
        const junction_data = JSON.parse(selected_junction_page.junction_data);
        const find_junction = _.find(junction_data, { id: value });

        // If the junction data does not have this pathway, then just return the original destination
        if (find_junction === undefined) {
          new_tiles_object.push(tile);
          return;
        }
        const find_page = _.find(pages, { page_id: find_junction.destination });

        console.log("selected page");
        console.log(find_page.title);
        console.log(find_page);
        new_tiles_object.push({ ...tile, title: find_junction.title });
      }
    });
    const ordered_tiles = _.sortBy(new_tiles_object, ["index_number"]);
    update_selected_tiles(ordered_tiles);
  }

  return (
    <div>
      <div>
        <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ marginTop: 15 }} as="h2">
                Tiles
              </Header>
              <Segment
                style={{ textAlign: "center", background: "#efefef" }}
                color="blue"
              >
                <TileNew user={user} />
              </Segment>
              <Segment
                style={{ textAlign: "center", background: "#efefef" }}
                color="red"
              >
                <Dropdown
                  placeholder="Filter For Pathway"
                  fluid
                  selection
                  defaultValue={"12"}
                  options={pathway_object_dropdown}
                  onChange={(e, { value }) => updateSelection(value)}
                />
              </Segment>
              <TilesList
                tiles={selected_tiles}
                user={user}
                pages={pages}
                moveSection={moveSection}
                junctionPages={junctionPages}
                updateIndexNumberManual={updateIndexNumberManual}
              />
            </Grid.Column>
            <Grid.Column>
              {/*//<div>
            // <iframe
            //   title='dasd'
            //   src="https://appetize.io/embed/c1jk5xuqvb61chhr2fk397418r?device=iphone6s&scale=65&orientation=portrait&osVersion=13.3"
            //   width="378px"
            //   height="800px"
            //   frameborder="0"
            //   scrolling="no"
            //   style={{marginLeft: 100}}
            //   >
            // </iframe>
            //</div>*/}
              <MobileTiles
                origin="tiles"
                trust={user["custom:trust"]}
                tiles={selected_tiles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default Tiles;

// import React from 'react';
// import { Header, Segment, Grid } from 'semantic-ui-react';
// import { connect } from 'react-redux';
// import _ from 'lodash';

// import TilesList from './TilesList';
// import TileNew from './TileNew';
// import MobileTiles from '../mobile/Tiles/MobileTiles';
// import { fetchTiles, fetchPages, update_Tile, fetchJunctionPages } from '../../actions';

// class Tiles extends React.Component {
// constructor(props) {
// super(props);
// this.state = {currentUser: props.user};
// }

// componentDidMount() {
//   this.props.fetchTiles(this.props.user['custom:trust']);
//   this.props.fetchPages(this.props.user['custom:trust']);
//   this.props.fetchJunctionPages(this.props.user['custom:trust'])
// }

// moveSection = async (index_number, movement) => {
//   const number_of_tiles = this.props.tiles.length
//   console.log("number_of_tiles:" + number_of_tiles)
//   console.log(index_number)
//   if(movement === 'up' && index_number !== 0){
//     const moving_tile = _.find(this.props.tiles, {index_number: index_number})
//     const tile_above = _.find(this.props.tiles, {index_number: index_number - 1})
//     moving_tile.index_number = index_number - 1
//     tile_above.index_number = index_number
//     console.log(moving_tile)
//     console.log(tile_above)
//     this.props.update_Tile({Item: {id: moving_tile.id, index_number: moving_tile.index_number}}, this.props.user['custom:trust']);
//     this.props.update_Tile({Item: {id: tile_above.id, index_number: tile_above.index_number} }, this.props.user['custom:trust']);
//   }
//   if(movement === 'down' && index_number !== number_of_tiles -1){
//     const moving_tile = _.find(this.props.tiles, {index_number: index_number})
//     const tile_below = _.find(this.props.tiles, {index_number: index_number + 1})
//     moving_tile.index_number = index_number + 1
//     tile_below.index_number = index_number
//     this.props.update_Tile({Item: {id: moving_tile.id, index_number: moving_tile.index_number}}, this.props.user['custom:trust']);
//     this.props.update_Tile({Item: {id: tile_below.id, index_number: tile_below.index_number}}, this.props.user['custom:trust']);
//   }
// }

//   render() {
//     return (
//       <div style={{marginLeft: 180}}>
//         <Grid columns='two' divided>
//           <Grid.Row>
//             <Grid.Column>
//               <Header as='h2'>Tiles</Header>
//               <Segment style={{textAlign: 'center', background: '#efefef'}} color='blue' >
//                 <TileNew user={this.props.user}/>
//               </Segment>
//               <TilesList
//                 tiles={this.props.tiles}
//                 user={this.props.user}
//                 pages={this.props.pages}
//                 moveSection={this.moveSection}
//                 junctionPages={junctionPages}
//               />
//             </Grid.Column>
//             <Grid.Column>
//             {/*//<div>
//             // <iframe
//             //   title='dasd'
//             //   src="https://appetize.io/embed/c1jk5xuqvb61chhr2fk397418r?device=iphone6s&scale=65&orientation=portrait&osVersion=13.3"
//             //   width="378px"
//             //   height="800px"
//             //   frameborder="0"
//             //   scrolling="no"
//             //   style={{marginLeft: 100}}
//             //   >
//             // </iframe>
//             //</div>*/}
//               <MobileTiles
//                 origin='tiles'
//                 trust={this.props.user['custom:trust']}
//               />
//             </Grid.Column>
//           </Grid.Row>
//         </Grid>
//       </div>

//     );
//   }
// }

// const mapStateToProps = state => {
//   return { tiles: state.tiles,
//             user: state.user,
//             pages: state.pages,
//             junctionPages: state.junctionPages
//           };
// };

// export default connect(mapStateToProps, { fetchTiles, fetchPages, update_Tile, fetchJunctionPages })(Tiles);
