import React, { Component } from "react";
import {
  Accordion,
  Icon,
  Form,
  Button,
  Divider,
  Dropdown,
  Grid,
  Checkbox,
  Menu,
  Radio,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import shorthash from "shorthash";
import _ from "lodash";

import NoAccess from "../NoAccess";
import { update_Tile, removeTile } from "../../actions";
import JunctoinSection from "../junctionPage/JunctionPageEdit";

import colours from "../../data/colours.json";
import icons from "../../data/icons.json";
class TilesSection extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      title: props.tile.title,
      colour: props.tile.colour,
      icon: props.tile.icon || "mental",
      destination: props.tile.destination,
      destination_name: props.tile.destination_name,
      user: props.user["custom:trust"],
      pages: props.pages,
      activeIndex: 1,
      uploadModalOpen: false,
      restrict_to_admin: props.tile.restrict_to_admin || false,
      junction_page_selected: props.tile.junction_page_selected || false,
      JunctionPage: this.props.tile.JunctionPage,
      alternative_link: this.props.tile.alternative_link,
      index_number: props.tile.index_number,
      menuSelected: "info",
      page_link_type: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.pages);
    if (
      this.props.tile.alternative_link === null &&
      this.props.tile.junction_page_selected === false
    ) {
      this.setState({ page_link_type: "normal" });
    }
    if (this.props.tile.junction_page_selected === true) {
      this.setState({ page_link_type: "junction" });
    }
    if (this.props.tile.alternative_link !== null) {
      if (this.props.tile.alternative_link === "Wellness") {
        this.setState({ page_link_type: "wellness" });
      }
      if (this.props.tile.alternative_link === "Timeline") {
        this.setState({ page_link_type: "timeline" });
      }
    }
  }

  handleMenueSelect = (data) => {
    this.setState({ menuSelected: data });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleChangePage(event) {
    console.log(event.target.value);
  }

  moveSectionUp = () => {
    this.props.moveSection(this.props.tile.index_number, "up");
  };
  moveSectionDown = () => {
    this.props.moveSection(this.props.tile.index_number, "down");
  };

  handleSubmit(event) {
    event.preventDefault();
    const params = {
      Item: {
        id: this.props.tile.id,
        timestamp: this.props.tile.timestamp,
        tile_id: this.props.tile.tile_id,
        title: this.state.title,
        destination: this.state.destination,
        destination_name: this.state.destination_name,
        colour: this.state.colour,
        restrict_to_admin: this.state.restrict_to_admin,
        junction_page_selected: this.state.junction_page_selected,
        JunctionPage: this.state.JunctionPage,
        alternative_link: this.state.alternative_link,
        icon: this.state.icon,
        //index_number: this.state.index_number,
      },
    };
    this.props.update_Tile(params, this.state.user);
  }

  handleDelete = (event) => {
    this.props.removeTile(this.props.tile, this.state.user);
  };

  handleAccessChange = () => {
    if (this.state.restrict_to_admin === false) {
      this.setState({ restrict_to_admin: true });
    }
    if (this.state.restrict_to_admin === true) {
      this.setState({ restrict_to_admin: false });
    }
  };
  handleJunctionChange = () => {
    if (this.state.junction_page_selected === false) {
      this.setState({ junction_page_selected: true });
    }
    if (this.state.junction_page_selected === true) {
      this.setState({ junction_page_selected: false });
    }
  };

  handleWellnessTrackerChange = () => {
    if (this.state.alternative_link === false) {
      this.setState({ alternative_link: true });
    }
    if (this.state.alternative_link === true) {
      this.setState({ alternative_link: false });
    }
  };
  renderAccessToggle = () => {
    if (this.props.user["custom:level"] === "basic") {
      return <div></div>;
    }
    return (
      <div>
        <Divider />
        <h4>Restrict access to admin</h4>
        <Checkbox
          toggle
          onChange={this.handleAccessChange}
          checked={this.state.restrict_to_admin}
        />
        {this.state.restrict_to_admin === true ? (
          <p style={{ color: "green" }}>Restricted to admin</p>
        ) : (
          <p>Access to basic users</p>
        )}
      </div>
    );
  };

  handlePageLinkChange = (data) => {
    switch (data) {
      case "normal":
        this.setState({
          junction_page_selected: false,
          JunctionPage: null,
          alternative_link: null,
          page_link_type: "normal",
        });
        break;
      case "junction":
        this.setState({
          junction_page_selected: true,
          alternative_link: null,
          page_link_type: "junction",
        });
        break;
      case "wellness":
        this.setState({
          junction_page_selected: false,
          alternative_link: "Wellness",
          page_link_type: "wellness",
        });
        break;
      case "timeline":
        this.setState({
          junction_page_selected: false,
          alternative_link: "Timeline",
          page_link_type: "timeline",
        });
        break;
      default:
        break;
    }
  };

  returnForm() {
    switch (this.state.menuSelected) {
      case "info":
        return (
          <div id={this.props.tile.tile_id}>
            <label>
              Name:
              <input
                name="title"
                type="text"
                value={this.state.title}
                onChange={this.handleChange}
              />
            </label>
            <Divider />
            <Grid columns={2} relaxed="very">
              <Grid.Column>
                <Dropdown
                  placeholder="Select Icon"
                  fluid
                  selection
                  search
                  defaultValue={this.props.tile.icon}
                  options={icons}
                  onChange={(e, { value }) => this.setState({ icon: value })}
                />
              </Grid.Column>
              <Grid.Column>
                <div style={{ textAlign: "center" }}>
                  <h5>Move Section</h5>
                  <Button.Group>
                    <Button onClick={this.moveSectionUp} content="Up" />
                    <Button.Or />
                    <Button onClick={this.moveSectionDown} content="Down" />
                  </Button.Group>
                </div>
              </Grid.Column>
            </Grid>
            <Divider />
            <label>Tile Colour</label>
            <Dropdown
              placeholder="Select Colour"
              fluid
              selection
              search
              defaultValue={this.props.tile.colour}
              options={colours}
              onChange={(e, { value }) => this.setState({ colour: value })}
            />
            <Divider />
          </div>
        );
        break;

      case "link":
        return (
          <div>
            <div style={{ marginBottom: 20 }}>
              <div>
                <Segment>
                  <h4>Select the type of page link you want:</h4>
                  <Form.Field>
                    <Radio
                      label="Normal Page Link"
                      name="radioGroup"
                      value="normal"
                      checked={this.state.page_link_type === "normal"}
                      onChange={() => this.handlePageLinkChange("normal")}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Junction Page"
                      name="radioGroup"
                      value="junction"
                      checked={this.state.page_link_type === "junction"}
                      onChange={() => this.handlePageLinkChange("junction")}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Wellness Tracker"
                      name="radioGroup"
                      value="wellness"
                      checked={this.state.page_link_type === "wellness"}
                      onChange={() => this.handlePageLinkChange("wellness")}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Timeline"
                      name="radioGroup"
                      value="timeline"
                      checked={this.state.page_link_type === "timeline"}
                      onChange={() => this.handlePageLinkChange("timeline")}
                    />
                  </Form.Field>
                </Segment>
                {this.returnlinkResponse()}
              </div>
            </div>
          </div>
        );
        break;
      case "admin":
        return (
          <div key={this.props.tile.id}>
            {this.renderAccessToggle()}
            <Divider />
            <h3>Manually update index number</h3>
            <label>
              {/* <input
                key={this.props.tile.id}
                name="title"
                type="number"
                value={this.state.index_number}
                onChange={(e) =>
                  this.setState({ index_number: Number(e.target.value) })
                }
              /> */}
              <h4>Index Number: {this.props.tile.index_number}</h4>
              <Button
                onClick={() =>
                  this.props.updateIndexNumberManual(
                    this.props.tile.id,
                    this.props.tile.index_number + 1
                  )
                }
              >
                Increase
              </Button>
              <Button
                onClick={() =>
                  this.props.updateIndexNumberManual(
                    this.props.tile.id,
                    this.props.tile.index_number - 1
                  )
                }
              >
                Decrease
              </Button>
            </label>
          </div>
        );
        break;

      default:
        break;
    }
  }

  returnlinkResponse() {
    var junction_data_to_parse = null;
    if (
      _.find(this.props.junctionPages, { id: this.state.JunctionPage }) !==
      undefined
    ) {
      junction_data_to_parse = _.find(this.props.junctionPages, {
        id: this.state.JunctionPage,
      });
    }

    const defaultLink = () => {
      return (
        <div>
          <h4>Default Link</h4>
          <Dropdown
            placeholder="Select Page"
            fluid
            selection
            search
            defaultValue={this.props.tile.destination}
            selectedLabel={this.state.destination}
            options={this.props.pages.map((page) => ({
              key: page.page_id,
              value: page.page_id,
              text: page.title,
            }))}
            onChange={(e, { value }) => this.setState({ destination: value })}
          />
        </div>
      );
    };

    const junctionSelection = () => {
      return (
        <div>
          <h4 style={{ marginTop: 20 }}>Select junction pages</h4>
          <Dropdown
            placeholder="Select Junction Page"
            fluid
            selection
            search
            defaultValue={this.state.JunctionPage}
            selectedLabel={this.state.JunctionPage}
            options={this.props.junctionPages.map((junction_page) => ({
              key: junction_page.id,
              value: junction_page.id,
              text: junction_page.title,
            }))}
            onChange={(e, { value }) => this.setState({ JunctionPage: value })}
          />
          {junction_data_to_parse ? (
            <JunctoinSection
              id={this.state.JunctionPage}
              junction_page={junction_data_to_parse}
              user={this.state.user}
            />
          ) : (
            <div></div>
          )}
        </div>
      );
    };
    switch (this.state.page_link_type) {
      case "normal":
        return <div>{defaultLink()}</div>;
        break;
      case "junction":
        return (
          <div>
            {defaultLink()}
            {junctionSelection()}
          </div>
        );
        break;
      case "wellness":
        return <div></div>;
        break;
      case "timeline":
        return <div></div>;
        break;
      default:
        break;
    }

    return (
      <div>
        <div>{defaultLink()}</div>
        <div>
          {this.state.junction_page_selected ? (
            <div>{junctionSelection()}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { activeIndex } = this.state;

    {
      _.find(this.props.junctionPages, { id: this.state.JunctionPage }) !==
      undefined ? (
        <JunctoinSection
          id={this.state.JunctionPage}
          junction_page={_.find(this.props.junctionPages, {
            id: this.state.JunctionPage,
          })}
          user={this.state.user}
        />
      ) : (
        <div></div>
      );
    }

    if (
      this.state.restrict_to_admin === true &&
      this.props.user["custom:level"] === "basic"
    ) {
      return (
        <Accordion
          disable
          styled
          style={{ marginTop: 10, background: "#fbfbfb" }}
        >
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            {this.state.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div>You do not have access to this tile</div>
          </Accordion.Content>
        </Accordion>
      );
    }
    return (
      <div key={this.props.tile.tile_id}>
        <Accordion
          disable
          styled
          style={{ marginTop: 10, background: "#fbfbfb" }}
        >
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />( {this.props.tile.index_number} ){" "}
            {this.props.tile.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Menu tabular>
              <Menu.Item
                name="info"
                active={this.state.menuSelected === "info"}
                onClick={() => this.handleMenueSelect("info")}
              />
              <Menu.Item
                name="link"
                active={this.state.menuSelected === "link"}
                onClick={() => this.handleMenueSelect("link")}
              />
              <Menu.Item
                name="admin"
                active={this.state.menuSelected === "admin"}
                onClick={() => this.handleMenueSelect("admin")}
              />
            </Menu>
            <Form>
              <Form.Field>{this.returnForm()}</Form.Field>
            </Form>
            <Divider />
            <h4>Options</h4>
            <Button primary onClick={this.handleSubmit} content="Save" />
            <Button secondary onClick={this.handleDelete} content="delete" />
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

export default connect(null, { update_Tile, removeTile })(TilesSection);
