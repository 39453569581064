import React from 'react'
import { Form, Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';
import _ from 'lodash';

import { addTile } from '../../actions';

class TileNew extends React.Component {
  constructor(props) {
  super(props);
  this.state = {description: '',
                title: '',
                modalOpen: false,
                user: props.user};
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = () => this.setState({
    modalOpen: true,
    description: '',
    title: ''
  })
  handleClose = () => this.setState({ modalOpen: false })


  handleChange(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }


  handleSubmit = (event) => {
    console.log(this.props.user['custom:trust'])
    event.preventDefault();
    const total_tiles = _.sortedIndex(this.props.tiles)
    const params = {
      Item: {
        title: this.state.title,
        colour: '#0c9f96',
        destination: null,
        destination_name: null,
        index_number: total_tiles,
        user_id: this.props.user['custom:trust'],
        tile_id: Date.now().toString(),
        alternative_link: null,
        junction_page_selected: false,
        icon: 'information'

      }
    };
    this.props.addTile(params, this.state.user['custom:trust']);
      this.setState({ modalOpen: false })
    }


  render() {
    return (
      <Modal
        size='mini'
        trigger={<Button primary onClick={this.handleOpen}>New Tile</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        >
        <Modal.Header>Add New Tile</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Name:
                <input
                  name="title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </label>
              <Divider/>
              <Button
                primary
                onClick={this.handleSubmit}
                content="Save"
              />
              <Button
                secondary
                onClick={this.handleClose}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return { user: state.user, tiles: state.tiles};
};

export default connect(mapStateToProps, { addTile })(TileNew);
