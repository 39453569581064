import React from "react";
import TilesSection from "./TilesSection";
import _ from "lodash";

const TilesList = (props) => {
  const ordered_tiles = _.sortBy(props.tiles, ["index_number"]);
  const tiles = ordered_tiles.map((tile) => {
    return (
      <TilesSection
        id={tile.tile_id}
        tile={tile}
        key={tile.id}
        user={props.user}
        pages={props.pages}
        moveSection={props.moveSection}
        junctionPages={props.junctionPages}
        updateIndexNumberManual={props.updateIndexNumberManual}
      />
    );
  });

  return <div>{tiles}</div>;
};

export default TilesList;
